import React from 'react';
import { FC } from 'react';
import { useRouter } from 'next/router';
import { ROUTES } from '../../constants';
import { Wrapper } from './Package.elements';

export interface PackageProps {
    symbol: string;
    color: string;
}

const PackageTemplate: FC<PackageProps> = ({ symbol, color }) => {
    const router = useRouter();

    return <Wrapper background={color}>{symbol}</Wrapper>;
};

export default PackageTemplate;
