import Link from 'next/link';
import { IconTemplate } from '@components/Icon/IconTemplate';
import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints, theme } from '../../styles/theme';

export const Item = styled.div`
    margin-bottom: ${rem(20)};

    @media (min-width: ${breakpoints.md}px) {
        display: flex;
        min-height: ${rem(185)};
    }
`;

export const Calendar = styled.div<{ isConference?: boolean }>`
    background: ${(prop) => (prop.isConference ? prop.theme.colors.primaryMedium : prop.theme.colors.primaryLight)};
    display: flex;
    flex-direction: column;
    padding: ${rem(15)} ${rem(10)};
    position: relative;

    @media (min-width: ${breakpoints.md}px) {
        width: ${rem(195)};
    }
`;

export const DateNumber = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-family: ${({ theme }) => theme.fonts.fontSecondary};
    color: ${({ theme }) => theme.colors.black};
`;

export const DateSeparator = styled.span`
    display: inline-block;
    padding: 0 ${rem(0)};
`;

export const Topic = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: ${rem(20)};
    border-radius: ${rem(16)};
    position: relative;
    width: 100%;

    @media (min-width: ${breakpoints.sm}px) {
        padding: ${rem(30)};
    }

    @media (min-width: ${breakpoints.md}px) {
        display: flex;
        flex-direction: column;
    }
`;

export const TopicPackageRow = styled.div`
    padding-bottom: ${rem(10)};
    margin-top: ${rem(-5)};

    @media (min-width: ${breakpoints.sm}px) {
        position: absolute;
        right: ${rem(25)};
        margin-top: 0;
        top: ${rem(55)};
    }
`;

export const TopicFirstRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: ${rem(15)};

    .label {
        color: ${({ theme }) => theme.colors.white};
        font-size: ${({ theme }) => theme.fontSizes.textSmall};
        padding: ${rem(4)} ${rem(12)};
        font-weight: 400;
        margin-left: ${rem(10)};
        border-radius: ${rem(16)};
    }

    .conferenceLabel {
        background: ${({ theme }) => theme.colors.black};
    }

    .courseLabel {
        background: ${({ theme }) => theme.colors.secondaryGrey};
    }
`;

export const TopicSecondRow = styled.div`
    padding-bottom: ${rem(25)};
`;

export const TopicThirdRow = styled.div`
    @media (min-width: ${breakpoints.sm}px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
`;

export const TopicTitle = styled.h3`
    font-size: ${({ theme }) => theme.fontSizes.h6};
    line-height: ${rem(24)};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-family: ${({ theme }) => theme.fonts.fontSecondary};
    color: ${({ theme }) => theme.colors.black};
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    display: inline-block;

    @media (min-width: ${breakpoints.sm}px) {
        width: 70%;
        font-size: ${({ theme }) => theme.fontSizes.h5};
        line-height: ${rem(30)};
    }

    &:hover,
    &:focus-visible {
        color: ${({ theme }) => theme.colors.primary};
    }
`;

export const TopicPlace = styled.div`
    padding-bottom: ${rem(10)};
    color: ${({ theme }) => theme.colors.secondaryGrey};
`;

export const Lecturers = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 80%;
`;

export const Tags = styled.div`
    padding: ${rem(15)} 0;

    @media (min-width: ${breakpoints.md}px) {
        padding: 0;
    }
`;

export const FavouriteIcon = styled(IconTemplate)`
    margin-right: ${rem(5)};

    svg {
        width: ${rem(14)};
        height: ${rem(14)};
    }
`;

export const FavouriteLabel = styled.span`
    color: ${({ theme }) => theme.colors.grey};
    display: none;

    @media (min-width: ${breakpoints.sm}px) {
        display: inline-block;
    }
`;

export const Favourite = styled.div<{ $hasRecordStatus: boolean }>`
    color: ${({ theme }) => theme.colors.secondaryGrey};
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const RecordStatus = styled.div`
    padding: ${rem(20)} 0 ${rem(20)} 0;
    width: 100%;
`;

export const RecordStatusSlider = styled.input<{ watchedPercentage: string }>`
    width: 100%;
    -webkit-appearance: none;
    height: ${rem(6)};
    background: ${({ theme }) => theme.colors.border};
    border-radius: ${rem(4)};
    background-image: linear-gradient(${({ theme }) => theme.colors.primary}, ${({ theme }) => theme.colors.primary});
    background-size: ${(prop) => prop.watchedPercentage}% 100%;
    background-repeat: no-repeat;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: ${rem(18)};
        width: ${rem(18)};
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.white};
        border: ${rem(4)} solid ${({ theme }) => theme.colors.primary};
        cursor: ew-resize;
        box-shadow: 0 0 4px 0 ${({ theme }) => theme.colors.primary};
        transition: background 0.3s ease-in-out;
    }
`;

export const RecordStatusLabel = styled.span`
    bottom: ${rem(15)};
    left: ${rem(20)};
    display: block;
    padding: ${rem(10)} 0;

    @media (min-width: ${breakpoints.xs}px) {
        position: absolute;
        padding: 0;
    }
`;

export const RecordStatusDate = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    bottom: ${rem(15)};
    right: ${rem(20)};
    display: block;

    @media (min-width: ${breakpoints.xs}px) {
        position: absolute;
    }
`;
