import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';
import AlertTemplate, { AlertTypes } from '@components/Alert/Alert';
import conference from '@components/Conference/Conference';
import { Icons } from '@components/Icon';
import { ModalTypes } from '@components/Modal/Modal';
import MyMaterialsTemplate from '@components/MyMaterials/MyMaterials';
import useTranslations from '@hooks/useTranslation';
import ButtonTemplate from '@ui/Button/Button';
import LecturerTemplate from '@ui/Lecturer/Lecturer';
import PackageTemplate from '@ui/Package/Package';
import TagTemplate from '@ui/Tag/Tag';
import { formatLecturerName } from '@utils/person.utils';
import { closestDateFrom } from '@utils/time.utils';
import { publicAssets } from '@utils/url.utils';
import moment from 'moment';
import { ROUTES, getImageBaseURL } from '../../constants';
import { SUBSCRIPTION_PACKAGES, SUBSCRIPTION_PACKAGES_KEYS } from '../../constants/packages';
import { CONFERENCE_REDIRECTS } from '../../constants/redirects';
import { ITrainingItem, ITrainingItemDate } from '../../interfaces/Calendar';
import { ILecturerItem } from '../../interfaces/Lecturer';
import { calendarApi } from '../../services/calendar.service';
import { RootState } from '../../store';
import { openModalType, setIsNeedToUpdateFavoriteList } from '../../store/ui';
import {
    DateNumber,
    DateSeparator,
    Favourite,
    FavouriteIcon,
    FavouriteLabel,
    Item,
    Lecturers,
    RecordStatus,
    RecordStatusDate,
    RecordStatusLabel,
    RecordStatusSlider,
    Tags,
    Topic,
    TopicFirstRow,
    TopicPackageRow,
    TopicPlace,
    TopicSecondRow,
    TopicThirdRow,
    TopicTitle,
} from './Event.elements';

export interface EventProps {
    event: ITrainingItem;
    iKey?: string | number;
}

const EventTemplate: FC<EventProps> = ({ event, iKey }) => {
    const { t } = useTranslations();

    const router = useRouter();

    const dispatch = useDispatch();

    const isConference = event?.conference?._id?.includes('Conference') || event?.event?._id?.includes('Conference');
    const isCourse = event?.course?._id?.includes('Course') || event?.event?._id?.includes('Course');
    const isLoggedInState = useSelector((state: RootState) => state.ui.isLoggedIn);

    const [eventDate, setEventDate] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isFavourite, setIsFavourite] = useState(event?.is_favorite);

    useEffect(() => {
        setIsFavourite(event?.is_favorite);
    }, [event]);

    useEffect(() => {
        if (event) {
            setEventDate(
                (router.asPath.includes(ROUTES.MY_PV_FAVOURITES) ? closestDateFrom(event.dates) : event.dates?.[0]) ||
                    event.eventDate
            );
        }
    }, []);

    useEffect(() => {
        setIsLoggedIn(isLoggedInState);
    }, [isLoggedInState]);

    const eventRecord =
        router.asPath.includes(ROUTES.MY_PV_CONFERENCES) ||
        router.asPath.includes(ROUTES.MY_PV_COURSES) ||
        router.asPath.includes(ROUTES.HOME_MY_PV_COURSES) ||
        router.asPath.includes(ROUTES.HOME_MY_PV_CONFERENCES)
            ? {
                  timeWatched: eventDate?.videos
                      ?.map((video: { timeWatched: number }) => video.timeWatched)
                      .reduce((a: number, b: number) => a + b, 0),
                  duration: eventDate?.videos
                      ?.map((video: { duration: number }) => video.duration)
                      .reduce((a: number, b: number) => a + b, 0),
                  created_at: eventDate?.videos?.[0]?.created_at,
              }
            : null;

    const handleOnFavoriteClick = async () => {
        try {
            setHasError(false);

            const eventKey = event?.course?._key || event?.conference?._key || event?.event?._key;

            if (!isLoggedIn) {
                return dispatch(
                    openModalType({
                        type: ModalTypes.LOGIN,
                    })
                );
            }

            if (isFavourite) {
                isCourse
                    ? await calendarApi.deleteCourseFromFavourite(eventKey)
                    : await calendarApi.deleteConferenceFromFavourite(eventKey);
            } else {
                await (isCourse
                    ? calendarApi.addCourseToFavourite(eventKey)
                    : calendarApi.addConferenceToFavourite(eventKey));
            }

            dispatch(setIsNeedToUpdateFavoriteList(true));

            setIsFavourite(!isFavourite);
        } catch {
            setHasError(true);
        }
    };

    const getEventPlaceForCourse = () => {
        const isOnline = !!event?.dates?.find((date: ITrainingItemDate) => date.venues?.length === 0);
        const venue = event?.dates?.find((date: ITrainingItemDate) => date.venues?.length > 0)?.venues?.[0];

        return `${venue ? venue.city : ''}${isOnline && venue ? ' | ' : ''}${isOnline ? t('event.isOnline') : ''}`;
    };

    const getEventPlaceForConference = () => {
        const isOnline = !!event?.dates?.find((date: ITrainingItemDate) => date.venues?.length === 0);
        const venue = event?.dates?.find((date: ITrainingItemDate) => date.venues?.length > 0)?.venues?.[0];

        return `${venue ? `${venue.city}, ${venue.name}` : ''}${isOnline && venue ? ' | ' : ''}${
            isOnline ? t('event.isOnline') : ''
        }`;
    };

    const getEventDateForCourse = () => {
        const from = moment(eventDate?.date?.from);
        const to = moment(eventDate?.date?.to);

        return to.diff(from, 'days') > 0 ? (
            <DateNumber>
                <span>{moment(from).format('D. M. ')} </span>
                <DateSeparator>–</DateSeparator>
                <span> {moment(to).format('D. M. ')}</span>
                <span className="year">{moment(to).format('YYYY')}</span>
                {router.pathname === ROUTES.HOME && (
                    <span className="label courseLabel">{t('event.trainingType')}</span>
                )}
            </DateNumber>
        ) : (
            <DateNumber>
                {moment(from).format('D. M. YYYY')}
                {router.pathname === ROUTES.HOME && (
                    <span className="label courseLabel">{t('event.trainingType')}</span>
                )}
            </DateNumber>
        );
    };

    const getEventDateForConference = () => {
        const minDate =
            event?.dates?.reduce((a, b) => {
                return a.date.from < b.date.from ? a : b;
            }).date.from || eventDate?.date?.from;
        const maxDate =
            event?.dates?.reduce((a, b) => {
                return a.date.to > b.date.to ? a : b;
            }).date.to || eventDate?.date?.to;
        const from = moment(minDate);
        const to = moment(maxDate);

        return to.diff(from, 'days') > 0 ? (
            <DateNumber>
                <span>{moment(from).format('D. M. ')} </span>
                <DateSeparator>–</DateSeparator>
                <span> {moment(to).format('D. M. ')}</span>
                <span className="year">{moment(to).format('YYYY')}</span>
                {router.pathname === ROUTES.HOME && (
                    <span className="label conferenceLabel">{t('event.conferenceType')}</span>
                )}
            </DateNumber>
        ) : (
            <DateNumber>
                {moment(from).format(minDate && maxDate ? 'D. M. YYYY' : 'M. YYYY')}
                {router.pathname === ROUTES.HOME && (
                    <span className="label conferenceLabel">{t('event.conferenceType')}</span>
                )}
            </DateNumber>
        );
    };

    const foundManualRedirectPathnameForConference = () => {
        return CONFERENCE_REDIRECTS.find(
            (redirect) => redirect._key === event?.event?._key || redirect._key === event?.conference?._key
        )?.link;
    };

    const buildRedirectPathname = (withoutManualRedirectCheck = false) => {
        if (isCourse) {
            return `${ROUTES.TRAINING}/${event.course?.slug || event.event?.slug}`;
        }

        if (isConference) {
            if (!withoutManualRedirectCheck) {
                const url = foundManualRedirectPathnameForConference();

                if (url) return url;
            }

            return `${ROUTES.CONFERENCE}/${event.conference?.slug || event.event?.slug}`;
        }
    };

    return (
        <Item key={iKey}>
            {hasError && <AlertTemplate type={AlertTypes.error} message={t('course.popular.error')} />}
            <Topic>
                <TopicFirstRow>
                    {isCourse && (eventDate?.date ? getEventDateForCourse() : <span />)}
                    {isConference && (event?.dates ? getEventDateForConference() : <span />)}
                    <Favourite $hasRecordStatus={false} onClick={handleOnFavoriteClick}>
                        <FavouriteIcon name={isFavourite ? Icons.FavouriteFull : Icons.Favourite} />
                        <FavouriteLabel>
                            {isFavourite ? t('course.popular.remove') : t('course.popular.add')}
                        </FavouriteLabel>
                    </Favourite>
                </TopicFirstRow>
                {eventDate?.modules?.length > 0 && (
                    <TopicPackageRow>
                        {eventDate?.modules
                            ?.filter((module: { _key: string }) => SUBSCRIPTION_PACKAGES_KEYS.includes(module._key))
                            ?.map((module: { _key: string }, key: number) => {
                                const subscriptionPackage = SUBSCRIPTION_PACKAGES.find(
                                    (subPackage) => subPackage.key === module._key
                                );

                                return (
                                    <PackageTemplate
                                        key={`package-${key}`}
                                        color={subscriptionPackage?.color}
                                        symbol={subscriptionPackage?.icon}
                                    />
                                );
                            })}
                    </TopicPackageRow>
                )}
                <TopicSecondRow>
                    <TopicPlace>
                        {isCourse && getEventPlaceForCourse()}
                        {isConference && getEventPlaceForConference()}
                    </TopicPlace>
                    <Link href={buildRedirectPathname()} target={`${foundManualRedirectPathnameForConference() ? '_blank' : ''}`}>
                        <TopicTitle>{event.course?.title || event.conference?.title || event?.event?.title}</TopicTitle>
                    </Link>
                </TopicSecondRow>
                {eventDate?.attachments?.length > 0 ? (
                    <MyMaterialsTemplate
                        materials={eventDate?.attachments}
                        eventSlug={event.course?.slug || event.conference?.slug || event?.event?.slug}
                    />
                ) : (
                    <>
                        {event?.tags?.filter((tag: { label: boolean }) => tag?.label)?.length > 0 && (
                            <TopicThirdRow>
                                <Lecturers>
                                    {(eventDate?.lectors || eventDate?.people || event?.dates?.[0]?.people)?.map(
                                        (person: ILecturerItem, key: number) => (
                                            <LecturerTemplate
                                                key={`lecturer-${key}`}
                                                image={
                                                    person?.photo
                                                        ? `${getImageBaseURL()}/media/people/${person?.photo}`
                                                        : `${publicAssets('/avatar.svg')}`
                                                }
                                                name={formatLecturerName(person)}
                                                slug={person?.slug}
                                            />
                                        )
                                    )}
                                </Lecturers>
                            </TopicThirdRow>
                        )}
                        <TopicThirdRow>
                            {event?.tags?.filter((tag: { label: boolean }) => tag?.label)?.length > 0 ? (
                                <Tags>
                                    {event?.tags
                                        ?.filter((tag: { label: boolean }) => tag.label)
                                        ?.map((tag: { color: string; title: string }, key: number) => (
                                            <TagTemplate key={`tag-${key}`} name={tag.title} color={tag.color} />
                                        ))}
                                </Tags>
                            ) : (
                                <Lecturers>
                                    {(eventDate?.lectors || eventDate?.people || event?.dates?.[0]?.people)?.map(
                                        (person: ILecturerItem, key: number) => (
                                            <LecturerTemplate
                                                key={`lecturer-${key}`}
                                                image={
                                                    person?.photo
                                                        ? `${getImageBaseURL()}/media/people/${person?.photo}`
                                                        : `${publicAssets('/avatar.svg')}`
                                                }
                                                name={formatLecturerName(person)}
                                                slug={person?.slug}
                                            />
                                        )
                                    )}
                                </Lecturers>
                            )}
                            <ButtonTemplate
                                marginTop={10}
                                onClick={() => {
                                    if (isConference) {
                                        const foundRedirectUrl = CONFERENCE_REDIRECTS.find(
                                            (redirect) =>
                                                redirect._key === event?.event?._key ||
                                                redirect._key === event?.conference?._key
                                        )?.link;

                                        if (foundRedirectUrl) return window.open(foundRedirectUrl, '_blank');
                                    }

                                    router.push({
                                        pathname: buildRedirectPathname(true),
                                    });
                                }}
                                text={t('homepage.calendar.button.detail')}
                            />
                        </TopicThirdRow>
                    </>
                )}
                {eventRecord?.created_at && (
                    <RecordStatus>
                        <RecordStatusSlider
                            type="range"
                            min="1"
                            max={eventRecord.duration}
                            value={eventRecord.timeWatched}
                            watchedPercentage={((100 * eventRecord.timeWatched) / eventRecord.duration).toFixed(2)}
                        />
                        <RecordStatusLabel>
                            {((100 * eventRecord.timeWatched) / eventRecord.duration).toFixed(2)}%{' '}
                            {t('training.record.label1')}
                        </RecordStatusLabel>
                        <RecordStatusDate>
                            {t('training.record.label2')} {moment(eventRecord.created_at).format('D. M. YYYY')}
                        </RecordStatusDate>
                    </RecordStatus>
                )}
            </Topic>
        </Item>
    );
};

export default EventTemplate;
