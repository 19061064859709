import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div<{ background: string }>`
    display: inline-flex;
    width: ${rem(30)};
    height: ${rem(20)};
    background: ${(props) => props.background};
    align-items: center;
    justify-content: center;
    border-radius: ${rem(24)};
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.textRegular};
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    margin: 0 ${rem(5)} 0 ${rem(5)};
`;
