import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { getApiURL, getCatalogUrl } from '../constants';

const zip = new JSZip();

export const handleDownloadClick = async (key: string, name: string, isCatalogDownload?: boolean, type?: string) => {
    if (isCatalogDownload) {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = getCatalogUrl();
        link.download = name;
        link.dispatchEvent(new MouseEvent('click'));

        return;
    }

    const originalImage = `${getApiURL()}/media/attachments/${key}`;

    if (type === 'application/pdf') {
        const link = document.createElement('a');
        link.href = originalImage;
        link.download = 'file.pdf';
        link.dispatchEvent(new MouseEvent('click'));

        return;
    }

    const image = await fetch(originalImage);

    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement('a');

    link.href = imageURL;
    link.download = name;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const handleDownloadClickAsZip = async (
    materials: { _key: string; name: string; mime_type: string }[],
    eventSlug: string
) => {
    const formattedFiles = materials.map((material) => ({
        url: `${getApiURL()}/media/attachments/${material._key}`,
        name: `${material.name}${getFileSuffixByMimeType(material.mime_type)}`,
    }));
    const arrOfFiles = formattedFiles.map((item) =>
        axios.get(item.url, { responseType: 'blob' }).then((resp) => {
            zip.file(item.name, resp.data);
        })
    );

    return Promise.all(arrOfFiles)
        .then(() => {
            zip.generateAsync({ type: 'blob' }).then(function (blob) {
                saveAs(blob, `${eventSlug}.zip`);
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

const getFileSuffixByMimeType = (mimeType: string) => {
    switch (mimeType) {
        case 'application/pdf':
            return '.pdf';
        case 'image/jpeg':
            return '.jpeg';
        case 'image/png ':
            return '.png';
        case 'text/plain':
            return '.txt';
        default:
            return '';
    }
};
