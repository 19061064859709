import React from 'react';
import { FC } from 'react';
import { useRouter } from 'next/router';
import { ROUTES } from '../../constants';
import { Image, ImageSection, Title, Wrapper } from './Lecturer.elements';

export interface LecturerProps {
    image: string;
    name: string;
    isBold?: boolean;
    slug?: string;
}

const LecturerTemplate: FC<LecturerProps> = ({ image, isBold, name, slug }) => {
    const router = useRouter();

    return (
        <Wrapper onClick={() => slug && router.push({ pathname: `${ROUTES.LECTURER}/${slug}` })}>
            <ImageSection>
                <Image src={image} />
            </ImageSection>
            <Title>{name}</Title>
        </Wrapper>
    );
};

export default LecturerTemplate;
