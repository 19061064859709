export const CONFERENCE_REDIRECTS = [
    {
        title: 'GDPR a zákon o ochrane osobných údajov 2024',
        link: 'https://konferencie.profivzdelavanie.sk/2024/gdpr-ochrana-osobnych-udajov/',
        lectors:
            'Lucia Bezáková, Ivan Makatura, Lucia Semančínová, Pavol Szabó, Roman Václav, Marcela Macová, Ján Golais, Jana Sisáková, Vladan Rámiš, Paula Babicová, Martin Cach, Miroslav Ilavský, Tibor Čamaj, Štefánia Šoltésová',
        eventDate: '11. - 12. 06. 2024',
        venue: 'Demänovská Dolina, Hotel Grand Jasná****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2311/Image74209.jpg',
        _key: '29707477',
    },
    {
        title: 'Školský zákon 2024',
        link: 'https://konferencie.profivzdelavanie.sk/2024/skolsky-zakon/',
        lectors:
            'Ingrid Konečná Veverková, Marek Havrila, Jana Sládečková, Jarmila Belešová, Anna Jurkovičová, Ján Sitarčík, Ivett Pavlis, Viera Hajdúková, Ingrid Kováčová, Jana Merašická, Ján Rusiňák, Marián Valent, Lívia Svetlošáková, Eva Smiková, Juliana Perečinská, Martin Mikluš',
        eventDate: '04. - 05. 06. 2024',
        venue: 'Vysoké Tatry, Grand hotel Permon****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2312/Image74773.jpg',
        _key: '29707479',
    },
    {
        title: 'Dane a účtovníctvo pod lupou v roku 2024',
        link: 'https://konferencie.profivzdelavanie.sk/2024/dane-a-uctovnictvo-pod-lupou/',
        lectors:
            'Ing. Vladimír Ozimý, JUDr. Ľubica Masárová, PhD., Ing. Soňa Ugróczy, Ing. Alica Orda Oravcová, Ing. Ján Skorka, Kamil Krajňak, Michal Klimek',
        eventDate: '15. - 17. 05. 2024',
        venue: 'Vysoké Tatry, Grandhotel Praha****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2312/Image74670.jpg',
        _key: '29707524',
    },
    {
        title: 'Zákon o informačných technológiách vo verejnej správe 2024',
        link: 'https://konferencie.profivzdelavanie.sk/2024/zakon-o-it-vo-verejnej-sprave/',
        lectors:
            'Michal Korytko, Ervín Šimko, Jozef Andraško, Pavol Frič, Jozef Medelský, Zuzana Regenda, Róbert Bučko, Ivan Ivančin, Rudolf Kellner, Antonín Korauš, Marián Danko, Kristián Rončkevič, Matúš Mesarčík, Nataša Brabcová',
        eventDate: '29. - 30. 04. 2024',
        venue: 'Demänovská Dolina, Wellness Hotel Chopok****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2401/Image75678.jpg',
        _key: '29707525',
    },
    {
        title: 'Pracovné právo a personalistika 2024',
        link: 'https://konferencie.profivzdelavanie.sk/2024/pracovne-pravo-a-personalistika/',
        lectors:
            'Jozef Toman, Marcel Dolobáč, Lucia Sabová Danková, Katarína Matulníková, Jana Sapáková, Monika Viszlay, Jozef Lukajka, Viktor Takács',
        eventDate: '16. - 17. 04. 2024',
        venue: 'Vysoké Tatry, Hotel Patria****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2310/Image73711.jpg',
        _key: '29707526',
    },
    {
        title: 'Odpadové hospodárstvo 2024',
        link: 'https://konferencie.profivzdelavanie.sk/2024/odpadove-hospodarstvo/',
        lectors:
            'Eleonóra Šuplatová, Michal Sebíň, Tomáš Schabjuk, Petr Bielan, Ján Chovanec, Marek Chovan, Jana Guštafiková, Rajmund Prusiewicz, Erwin Binner, Peter Pajerchin, Branislav Tréger, Tibor Švec, Ľubomír Ďuračka, Martina Rodenáková',
        eventDate: '18. - 19. 03. 2024',
        venue: 'Vysoké Tatry, Hotel Patria****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2310/Image73710.jpg',
        _key: '29707527',
    },
    {
        title: 'BOZP 2024',
        link: 'https://konferencie.profivzdelavanie.sk/2024/bozp/',
        lectors:
            'JUDr. Jozef Lukajka, PhD., Ing. Stanislav Krajňák, PhD., MBA, Peter Hollý, doc. Ing. Katarína Hollá, PhD., prof. Ing. Jozef Martinka, PhD., Ing. Peter Pikla',
        eventDate: '12. - 13. 03. 2024',
        venue: 'Vysoké Tatry, Hotel Patria****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2310/Image73801.jpg',
        _key: '29707528',
    },
    {
        title: 'Verejné obstarávanie 2024',
        link: 'https://konferencie.profivzdelavanie.sk/2024/verejne-obstaravanie/',
        lectors:
            'Branislav Hudec, Adam Nociar, Vladimír Kordoš, Ondrej Matej, Terézia Nagyová, Martin Hoffmann, Pavol Kováčik, Miroslav Chlipala, Martin Boržík, Anna Juráková, Ľubomír Kubička, Patricia Tóthová, Marian Jaššo, Andrej Mikláš, Roman Hulák, Katarína Hreusová, Jozef Božik',
        eventDate: '13. - 14. 02. 2024',
        venue: 'Vysoké Tatry, Hotel Patria****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2308/Image72348.jpg',
        _key: '29707529',
    },
    {
        title: 'Daňový a odvodový kolotoč, ukončenie roka 2023 a zmeny pre rok 2024',
        link: 'https://konferencie.profivzdelavanie.sk/2023/danovy-a-odvodovy-kolotoc/',
        lectors: 'Ing. Vladimír Ozimý, Júlia Pšenková, Ing. Soňa Ugróczy',
        eventDate: '06. - 08. 12. 2023',
        venue: 'Starý Smokovec, Grandhotel Starý Smokovec****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2301/Image68084.jpg',
        _key: '29707530',
    },
    {
        title: 'Stavebné právo 2023',
        link: 'https://konferencie.profivzdelavanie.sk/2023/stavebne-pravo/',
        lectors:
            'Slavomíra Salajová, Peter Kotvan, Roman Skorka, Martin Hypký, Pavol Kováčik, Ján Petržala, Pavol Fürst, Eva Kováčechová, Tomáš Funtík, Martin Baloga, Tibor Hlinka, Martina Zeleňáková',
        eventDate: '27. - 28. 11. 2023',
        venue: 'Demänovská Dolina, Hotel Grand Jasná****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2301/Image68099.jpg',
        _key: '29707531',
    },
    {
        title: 'Československý daňový a účtovný kongres 2023',
        link: 'https://konferencie.profivzdelavanie.sk/2023/cs-danovy-a-uctovny-kongres/',
        lectors:
            'Ing. Daniela Klučková, Ing. Peter Horniaček, Ing. Vladimír Ozimý, Ing. Alica Orda Oravcová, Ing. Igor Pantůček, Mgr. et Mgr. Mojmír Beňo, Ing. Zdeněk Urban, Ing. Jana Rusmanová, Ing. Mária Makrovská, CA, DipIFR, Ing. Tomáš Goláň, Ing. Martin Plachý, Ing. Jan Tecl, PhD., JUDr. Toško Beran, Ing. Bc. Zdeněk Hrdlička',
        eventDate: '13. - 15. 11. 2023',
        venue: 'Mikulov, Hotel Galant****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2301/Image68086.jpg',
        _key: '29707532',
    },
    {
        title: 'DNA Lídra 2023',
        link: 'http://www.dnalidra.sk/',
        lectors: null,
        eventDate: '23. 10. 2023',
        venue: 'Žilina, Mestské divadlo v Žiline',
        img: 'https://www.profivzdelavanie.sk/media/Base/2407/Image79685.jpg',
        _key: '29707533',
    },
    {
        title: 'Kybernetická bezpečnosť 2023',
        link: 'https://konferencie.profivzdelavanie.sk/2023/kyberneticka-bezpecnost/',
        lectors:
            'Ján Lichvár, Matej Šalmík, Ivan Makatura, Ivan Kotuliak, Zuzana Omelková, Tomáš Hettych, Michal Rampášek, Roman Václav, Roman Čupka, Martina Repíková, Miroslav Chlipala, Katarína Kampová, Lukáš Hlavička, Juraj Nemeček, Miroslav Havelka, Luboš Fryc, Marek Zeman, Katarína Vladár Lešková, Július Selecký, Lukáš Balážik, Lukáš Kurtiniak, Pavol Balžanka, Petra Kotuliaková, Filip Tubler, Miloš Tomka',
        eventDate: '02. - 03. 10. 2023',
        venue: 'Demänovská Dolina, Hotel Grand Jasná****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2301/Image68098.jpg',
        _key: '29707534',
    },
    {
        title: 'Odpadové hospodárstvo 2023',
        link: 'https://konferencie.profivzdelavanie.sk/2023/odpadove-hospodarstvo/',
        lectors:
            'Miroslava Masničáková, Tomáš Schabjuk, Radovan Potočár, Roman Skorka, Jan Maršák, Juraj Musil, Ján Jenčo, František Beták, Petr Havelka, Martin Drozd, Ján Chovanec, Ingrid Jenisová, Marek Prítyi, Lukáš Skurka, Tomáš Hospodár, Katarína Schneider, Pavol Prepiak',
        eventDate: '21. - 22. 09. 2023',
        venue: 'Demänovská Dolina, Hotel Grand Jasná****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2301/Image68097.jpg',
        _key: '29707535',
    },
    {
        title: 'Školský zákon 2023',
        link: 'https://konferencie.profivzdelavanie.sk/2023/skolsky-zakon/',
        lectors:
            'Ingrid Konečná Veverková, Marek Havrila, Jana Sládečková, Jarmila Belešová, Anna Jurkovičová, Ján Sitarčík, Ivett Pavlis, Viera Hajdúková, Eva Pupíková, Ingrid Kováčová, Janka Píšová, Jana Merašická, Ján Rusiňák, Ján Horecký',
        eventDate: '20. - 21. 06. 2023',
        venue: 'Vysoké Tatry, Grand hotel Permon****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2303/Image69639.jpg',
        _key: '29707536',
    },
    {
        title: 'GDPR a zákon o ochrane osobných údajov 2023',
        link: 'https://konferencie.profivzdelavanie.sk/2023/GDPR-ochrana-osobnych-udajov/',
        lectors:
            'Lucia Semančínová, Marek Frecer, Roman Václav, Marcela Macová, Ján Golais, Angela Sobolčiaková, Jana Sisáková, Martin Oczvirk, Vladan Rámiš, Paula Babicová, Lucia Váryová, Martin Cach, Ondrej Zimen',
        eventDate: '05. - 06. 06. 2023',
        venue: 'Tatranská Lomnica, Grandhotel Praha****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2301/Image68094.jpg',
        _key: '29707537',
    },
    {
        title: 'Dane a účtovníctvo pod lupou v roku 2023',
        link: 'https://konferencie.profivzdelavanie.sk/2023/dane-a-uctovnictvo-pod-lupou/',
        lectors: 'Ing. Vladimír Ozimý, Ing. Soňa Ugróczy, Ing. Rastislav Brenčič',
        eventDate: '29. - 31. 05. 2023',
        venue: 'Vysoké Tatry, Grand hotel Permon****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2301/Image68085.jpg',
        _key: '29707538',
    },
    {
        title: 'Pracovné právo 2023',
        link: 'https://konferencie.profivzdelavanie.sk/2023/pracovne-pravo/',
        lectors:
            'Soňa Filípková, Ľubomír Kadlečík, Jozef Toman, Marcel Dolobáč, Tomáš Homolya, Paulína Pokorná, Lucia Sabová Danková, Michal Drobný, Markéta Hritz, Martin Gymerský, Katarína Matulníková',
        eventDate: '23. - 24. 03. 2023',
        venue: 'Demänovská Dolina, Hotel Grand Jasná****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2301/Image68095.jpg',
        _key: '29707539',
    },
    {
        title: 'Verejné obstarávanie 2023',
        link: 'https://konferencie.profivzdelavanie.sk/2023/verejne-obstaravanie/',
        lectors:
            'Branislav Hudec, Adam Nociar, Asena Filková, Róbert Majchrák, Vladimír Kordoš, Ondrej Matej, Terézia Nagyová, Štefan Holý, Martin Hoffmann, Boris Kordoš, Jozef Daňko, Roman Osika, Lýdia Budziňáková, Dušan Donoval, Peter Kubovič, Barney Smith',
        eventDate: '13. - 14. 02. 2023',
        venue: 'Vysoké Tatry, Hotel Patria****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2301/Image68092.jpg',
        _key: '29707540',
    },
    {
        title: 'Daňový a odvodový kolotoč, ukončenie roka 2022 a zmeny pre rok 2023',
        link: 'https://konferencie.profivzdelavanie.sk/2022/danovy-a-odvodovy-kolotoc-ukoncenie-roka-2022-a-zmeny-pre-rok-2023/',
        lectors: 'Ing. Vladimír Ozimý, Júlia Pšenková, Ing. Soňa Ugróczy',
        eventDate: '07. - 09. 12. 2022',
        venue: 'Starý Smokovec, Grandhotel Starý Smokovec****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2209/Image65523.png',
        _key: '29707541',
    },
    {
        title: 'Stavebné právo 2022',
        link: 'https://konferencie.profivzdelavanie.sk/2022/stavebne-pravo/',
        lectors:
            'Slavomíra Salajová, Peter Kotvan, Roman Skorka, Štefan Holý, Milota Sidorová, Martin Hypký, Pavol Kováčik, Matej Košalko, Tatiana Prokopová, Juraj Suchánek, Viera Rajprichová, Mário Glos, Ján Petržala, Marek Perdík, Jozef Olašák, Tibor Németh, Adriana Jankovičová, Martin Chren',
        eventDate: '28. - 29. 11. 2022',
        venue: 'Demänovská Dolina, Hotel Grand Jasná****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2107/Image57134.png',
        _key: '29707542',
    },
    {
        title: 'Československý daňový a účtovný kongres 2022',
        link: 'https://konferencie.profivzdelavanie.sk/2022/cs-danovy-a-uctovny-kongres/',
        lectors:
            'Ing. Daniela Klučková, Ing. Mária Horváthová, Ing. Peter Horniaček, Ing. Vladimír Ozimý, Ing. Alica Orda Oravcová, Ing. Igor Pantůček, Ing. Jan Tecl, Mgr. et Mgr. Mojmír Beňo, Ing. Zdeněk Urban, Ing. Jana Rusmanová',
        eventDate: '07. - 09. 11. 2022',
        venue: 'Mikulov, Hotel Galant****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2204/Image62587.png',
        _key: '29707543',
    },
    {
        title: 'Kybernetická bezpečnosť 2022',
        link: 'https://konferencie.profivzdelavanie.sk/2022/kyberneticka-bezpecnost/',
        lectors:
            'Pavol Adamec, Pavol Draxler, Igor Straka, Radoslav Repa, Matej Šalmík, Štefan Pilár, Jindřich Kalíšek, Ivan Makatura, Jozef Andraško, Pavel Zeman, Ivan Kotuliak, Marián Illovský, Roman Konečný, Tomáš Hettych, Rastislav Janota, René Baran, Peter Veselý, Michal Rampášek, Roman Václav, David Dvořák, Michal Ries, Martin Fábry, Václav Hřích, Roman Čupka, Maroš Barabas, Petr Hněvkovský, Tomáš Loveček, Kristián Košťál, Miroslav Michalko, Miroslav Chlipala',
        eventDate: '03. - 04. 10. 2022',
        venue: 'Demänovská Dolina, Hotel Grand Jasná****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2112/Image60308.png',
        _key: '29707544',
    },
    {
        title: 'DNA Lídra 2022',
        link: 'https://dnalidra.sk/2022/',
        lectors:
            'Emma Drobná, Peter Hámor, Ladislav Kužela, Matej Krajčík, Emil Schultz, Karol Suchánek, Tomáš Masaryk, Jana Tomas',
        eventDate: '26. 09. 2022',
        venue: 'Bratislava, Nová scéna',
        img: 'https://www.profivzdelavanie.sk/media/Base/2202/Image61360.png',
        _key: '29707556',
    },
    {
        title: 'Odpadové hospodárstvo 2022',
        link: 'https://konferencie.profivzdelavanie.sk/2022/odpadove-hospodarstvo/',
        lectors:
            'Eleonóra Šuplatová, Juraj Číž, Michal Sebíň, Juraj Smatana, Miroslava Masničáková, Miroslav Jurkovič, Tomáš Schabjuk, Rastislav Zamboj, Peter Krasnec, Oľga Trcková, Zuzana Krajčovičová, Rudolf Mackovič, Petr Bielan, Ján Csontos, Radovan Potočár, Ladislav Halász, Pavol Kováčik, Peter Gallovič, Annamária Tóthová',
        eventDate: '22. - 23. 09. 2022',
        venue: 'Demänovská Dolina, Hotel Grand Jasná****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2201/Image60835.png',
        _key: '29707555',
    },
    {
        title: 'GDPR a zákon o ochrane osobných údajov 2022',
        link: 'https://konferencie.profivzdelavanie.sk/2022/GDPR-ochrana-osobnych-udajov/',
        lectors:
            'JUDr. Marcela Macová, PhD., Jakub Berthoty, LL.M., JUDr. Andrej Poruban, PhD., JUDr. Lucia Bezáková, Mgr. Zuzana Motúzová, JUDr. Lucia Sabová Danková, Mgr. Martin Cach, JUDr. Paula Babicová, JUDr. Lucia Semančínová, JUDr. Simona Klučiarová, Mgr. Ing. Ivana Draškovič, JUDr. Lucia Váryová, PhD., JUDr. Vladan Rámiš, PhD., Mgr. Michal Nohel',
        eventDate: '06. - 07. 06. 2022',
        venue: 'Tatranská Lomnica, Grandhotel Praha****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2201/Image60816.png',
        _key: '29707554',
    },
    {
        title: 'Dane a účtovníctvo pod lupou v roku 2022',
        link: 'https://konferencie.profivzdelavanie.sk/2022/dane-a-uctovnictvo-pod-lupou/',
        lectors: 'Ing. Vladimír Ozimý, Ing. Soňa Ugróczy',
        eventDate: '30. 05. 2022 - 01. 06. 2022',
        venue: 'Vysoké Tatry, Grand hotel Permon****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2204/Image62491.png',
        _key: '29707553',
    },
    {
        title: 'Pracovné právo 2022',
        link: 'https://konferencie.profivzdelavanie.sk/2022/pracovne-pravo/',
        lectors:
            'JUDr. et Mgr. Jozef Toman, PhD., JUDr. Viktor Križan, PhD., doc. JUDr. Marcel Dolobáč PhD., JUDr. Lucia Sabová Danková, Mgr. Andrej Lasz, JUDr. Ing. Matej Šebesta, MBA, Mgr. Kamil Košík, JUDr. Nikola Jaborníková, JUDr. Mária Kasanová, Ing. František Foltán, JUDr. Daniel Širhal, MBA',
        eventDate: '05. - 06. 05. 2022',
        venue: 'Tatranská Lomnica, Grandhotel Praha****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2112/Image60265.png',
        _key: '29707552',
    },
    {
        title: 'Daňový a odvodový kolotoč, ukončenie roka 2021 a zmeny pre rok 2022',
        link: 'https://konferencie.profivzdelavanie.sk/2021/danovy-a-odvodovy-kolotoc-ukoncenie-roka-2021-a-zmeny-pre-rok-2022-Smokovec/',
        lectors: 'Ing. Vladimír Ozimý, Júlia Pšenková',
        eventDate: '08. - 10. 12. 2021',
        venue: 'online',
        img: 'https://www.profivzdelavanie.sk/media/Base/2107/Image57142.png',
        _key: '29707551',
    },
    {
        title: 'Zmeny v oblasti daní, účtovníctva a miezd od 1. 1. 2022',
        link: 'https://konferencie.profivzdelavanie.sk/2021/zmeny-v-oblasti-dani-uctovnictva-a-miezd-od-1-1-2022/',
        lectors: 'Ing. Mária Horváthová, Júlia Pšenková, Ing. Alica Orda Oravcová',
        eventDate: '02. 12. 2021',
        venue: 'online',
        img: 'https://www.profivzdelavanie.sk/media/Base/2107/Image57478.png',
        _key: '29707550',
    },
    {
        title: 'Verejné obstarávanie 2021',
        link: 'https://konferencie.profivzdelavanie.sk/2021/verejne-obstaravanie/',
        lectors:
            'JUDr. Juraj Tkáč, PhD., JUDr. Róbert Majchrák, JUDr. Ján Azud, Branislav Hudec, Mgr. Michal Roháč, Ing. Tomáš Cholasta, Ing. Roman Kuchár, Mgr. Adéla Havlová, LL.M., Adam Nociar, Mgr. Lucia Slovák, JUDr. Katarína Mrázová, Peter Kotvan',
        eventDate: '25. - 26. 11. 2021',
        venue: 'Šamorín, X-BIONIC® HOTEL',
        img: 'https://www.profivzdelavanie.sk/media/Base/2107/Image57138.png',
        _key: '29707549',
    },
    {
        title: 'GDPR a zákon o ochrane osobných údajov 2021',
        link: 'https://konferencie.profivzdelavanie.sk/2021/GDPR-ochrana-osobnych-udajov-podla-noveho-zakona/',
        lectors:
            'Jakub Berthoty, LL.M., JUDr. Lucia Bezáková, JUDr. Štefan Pilár, Mgr. Ján Golais, Mgr. Zuzana Motúzová, JUDr. Angela Sobolčiaková, LL.M., JUDr. Jana Sisáková, JUDr. Michaela Jánošíková, Mgr. Rudolf Obert, Mgr. Martin Cach, JUDr. Paula Babicová, Mgr. Gabriela Holíková, LL.M., JUDr. Lucia Semančínová',
        eventDate: '11. - 12. 11. 2021',
        venue: 'Belá, Hotel Château Belá*****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2107/Image57137.png',
        _key: '29707548',
    },
    {
        title: 'DNA Lídra 2021',
        link: 'https://dnalidra.sk/2021/',
        lectors: null,
        eventDate: '27. 10. 2021',
        venue: 'Bratislava, Radošinské naivné divadlo',
        img: 'https://www.profivzdelavanie.sk/media/Base/2105/Image56345.jpg',
        _key: '29707547',
    },
    {
        title: 'Kybernetická bezpečnosť 2021',
        link: 'https://konferencie.profivzdelavanie.sk/2021/kyberneticka-bezpecnost/',
        lectors:
            'JUDr. Ing. Miroslav Chlipala, PhD., Ing. Tomáš Zaťko, Ing. Ivan Makatura, CRISC, CDPSE, Mgr. Marek Zeman, PhD., JUDr. Štefan Pilár, JUDr. Ing. Jindřich Kalíšek, Ph.D. CIPP/E CIPM, prof. Ing. Tomáš Loveček, PhD., Ing. David Dvořák, CISA, CISM, CRISC, CDPSE, ITIL Expert, ISA/IEC 62443 Cybersecurity Expert, Pavol Draxler, Mgr. Rastislav Janota, Ing. Tomáš Hettych, CISA, CISM, CGEIT, CRISC, CDPSE, Ing. Radek Beneš, MSc., Pavel Zeman, JUDr. René Baran, PhD., Mgr. Martin Gymerský, Ivan Kotuliak, plk. doc. Ing. Stanislav Šišulák, PhD., PhDr. Peter Veselý, PhD. MBA, Marián Illovský, Doc. Ing. Dagmar Vidríková, PhD., Mgr. Martina Lisická, Ing. Miroslav Havelka, Zuzana Omelková, Roman Konečný, René Baran, Tomáš Loveček',
        eventDate: '04. - 05. 10. 2021',
        venue: 'Demänovská Dolina, Hotel Grand Jasná****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2107/Image57132.png',
        _key: '29707546',
    },
    {
        title: 'Odpadové hospodárstvo 2021',
        link: 'https://konferencie.profivzdelavanie.sk/2021/odpadove-hospodarstvo/',
        lectors:
            'Ing. Peter Gallovič, Martin Basila, Roman Vandák, Juraj Smatana, Tomáš Baloch, Miroslava Masničáková, Daniel Kleman, Marcel Kalman, Ján Kundľa, Miroslav Jurkovič, Martin Maslák, Marián Kobolka, Tomáš Schabjuk, Robert Procházka, Marcel Vrátný, Kamil Potoma, Rastislav Zamboj',
        eventDate: '27. - 28. 09. 2021',
        venue: 'Demänovská Dolina, Hotel Grand Jasná****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2107/Image57131.png',
        _key: '29707545',
    },
    {
        title: 'UPTERDAM 2021',
        link: 'https://www.upterdam.sk/',
        lectors: null,
        eventDate: '14. 09. 2021',
        venue: 'Senec, D1 Expo-Business Centrum',
        img: 'https://www.profivzdelavanie.sk/media/Base/2103/Image55213.png',
        _key: '29753286',
    },
    {
        title: 'Dane a účtovníctvo pod lupou v roku 2021',
        link: 'https://konferencie.profivzdelavanie.sk/2021/dane-a-uctovnictvo-pod-lupou/',
        lectors: 'Ing. Vladimír Ozimý, Ing. Soňa Ugróczy',
        eventDate: '31. 05. 2021 - 02. 06. 2021',
        venue: 'Vysoké Tatry, Hotel Patria****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2101/Image53238.jpg',
        _key: '29707523',
    },
    {
        title: 'Konkurz a reštrukturalizácia 2021',
        link: 'https://konferencie.profivzdelavanie.sk/2021/konkurz-a-restrukturalizacia/',
        lectors: 'JUDr. Martin Maliar, JUDr. Danica Birošová, Ing. Eva Barčíková',
        eventDate: '24. - 25. 05. 2021',
        venue: 'Tatranská Lomnica, Grandhotel Praha****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2107/Image57141.png',
        _key: '29707522',
    },
    {
        title: 'Zákon o informačných technológiách vo verejnej správe 2020 | 2021',
        link: 'https://konferencie.profivzdelavanie.sk/2020/zakon-o-IT-vo-verejnej-sprave/',
        lectors:
            'Ivan Makatura, Štefan Szilva, Jozef Andraško, Richard Kiškováč, Stanislav Guláš, Martin Florián, Marián Danko, Martin Bezek, Martin Konečný, Pavol Frič, Martin Thurzo, Peter Dolíhal, Michal Ďorda',
        eventDate: '16. - 17. 03. 2021',
        venue: 'Tatranská Lomnica, Grandhotel Praha****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2107/Image57140.png',
        _key: '29707521',
    },
    {
        title: 'Pracovné právo 2020 | 2021',
        link: 'https://konferencie.profivzdelavanie.sk/2021/pracovne-pravo/',
        lectors:
            'JUDr. et Mgr. Jozef Toman, PhD., JUDr. Jana Žuľová, PhD., Ing. Nora Lauková, doc. JUDr. Marcel Dolobáč PhD., JUDr. Martin Vavro, JUDr. Lucia Sabová Danková, JUDr. Roman Soska, Ing. Karol Zimmer, JUDr. Vladimír Grác',
        eventDate: '08. - 09. 02. 2021',
        venue: 'online',
        img: 'https://www.profivzdelavanie.sk/media/Base/2107/Image57135.png',
        _key: '29707520',
    },
    {
        title: 'Trestné právo 2020',
        link: 'https://konferencie.profivzdelavanie.sk/2020/trestne-pravo/',
        lectors:
            'JUDr. Ján Šanta, PhD., LL.M., MBA, prof. JUDr. Tomáš Strémy, PhD., prof. JUDr. Jozef Čentéš, PhD., JUDr. Ľubica Masárová, PhD.',
        eventDate: '14. - 15. 12. 2020',
        venue: 'Demänovská Dolina, Hotel Tri Studničky****',
        img: 'https://www.profivzdelavanie.sk/media/Base/1912/Image45427.png',
        _key: '29707519',
    },
    {
        title: 'Daňový a odvodový kolotoč, ukončenie roka 2020 a zmeny pre rok 2021',
        link: 'https://konferencie.profivzdelavanie.sk/2020/danovy-a-odvodovy-kolotoc-ukoncenie-roka-2020-a-zmeny-pre-rok-2021-Smokovec/',
        lectors: 'Ing. Vladimír Ozimý, RNDr. Jozef Mihál, Ing. Soňa Ugróczy',
        eventDate: '07. - 09. 12. 2020',
        venue: 'Vysoké Tatry, Grandhotel Starý Smokovec****',
        img: 'https://www.profivzdelavanie.sk/media/Base/1912/Image45514.png',
        _key: '29707518',
    },
    {
        title: 'Zmeny v oblasti daní, účtovníctva a miezd od 1. 1. 2021',
        link: 'https://konferencie.profivzdelavanie.sk/2020/zmeny-v-oblasti-dani-uctovnictva-a-miezd-od-1-1-2021/',
        lectors: 'Ing. Mária Horváthová, Júlia Pšenková, Ing. Alica Orda Oravcová',
        eventDate: '03. 12. 2020',
        venue: 'Bratislava, Hotel Tatra Bratislava****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2005/Image48324.png',
        _key: '29707517',
    },
    {
        title: 'GDPR a zákon o ochrane osobných údajov 2020',
        link: 'https://konferencie.profivzdelavanie.sk/2020/GDPR-ochrana-osobnych-udajov-podla-noveho-zakona/',
        lectors:
            'Mgr. Irena Hudecová, Daniel Paracka, Jakub Berthoty, Irena Hudecová, Lucia Bezáková, Štefan Pilár, Jindřich Kalíšek, Pavel Lacko, Roman Soska, Ivan Makatura, Lucia Semančínová, Marek Frecer, Adrian Bagala, Pavol Szabó, Viktor Ewerling, Viktória Nagyová Tenkač',
        eventDate: '30. 11. 2020 - 01. 12. 2020',
        venue: 'Tatranská Lomnica, Grandhotel Praha****',
        img: 'https://www.profivzdelavanie.sk/media/Base/1912/Image45425.png',
        _key: '29707516',
    },
    {
        title: 'Daňový a odvodový kolotoč, ukončenie roka 2020 a zmeny pre rok 2021',
        link: 'https://konferencie.profivzdelavanie.sk/2020/danovy-a-odvodovy-kolotoc-ukoncenie-roka-2020-a-zmeny-pre-rok-2021-Kaskady/',
        lectors: 'Ing. Vladimír Ozimý, RNDr. Jozef Mihál, Ing. Soňa Ugróczy',
        eventDate: '25. - 27. 11. 2020',
        venue: 'Sliač, Hotel & Spa Resort Kaskady****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2005/Image48252.png',
        _key: '29707515',
    },
    {
        title: 'Konkurz a reštrukturalizácia 2020',
        link: 'http://konferencie.profivzdelavanie.sk/2020/konkurz-a-restrukturalizacia/',
        lectors:
            'JUDr. Martin Maliar, Prof. JUDr. Milan Ďurica, PhD., JUDr. Branislav Pospíšil, JUDr. Jaroslav Macek, JUDr. Danica Birošová',
        eventDate: '15. - 16. 10. 2020',
        venue: 'Demänovská Dolina, Hotel Tri Studničky****',
        img: 'https://www.profivzdelavanie.sk/media/Base/1912/Image45630.png',
        _key: '29707514',
    },
    {
        title: 'Daňový a odvodový kolotoč v roku 2020',
        link: 'https://konferencie.profivzdelavanie.sk/2020/danovy-a-odvodovy-kolotoc/',
        lectors: 'Ing. Vladimír Ozimý, RNDr. Jozef Mihál, Ing. Soňa Ugróczy',
        eventDate: '14. 10. 2020',
        venue: 'Bratislava, Hotel Saffron****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2005/Image48665.png',
        _key: '29707513',
    },
    {
        title: 'Odpadové hospodárstvo 2020',
        link: 'http://konferencie.profivzdelavanie.sk/2020/odpadove-hospodarstvo/',
        lectors:
            'Ing. Peter Gallovič, Mgr. Janette Smažáková, Ing. Tomáš Schabjuk, Bc. Zuzana Čachová, Ing. Peter Šimurka, JUDr. Katarína Brath Liebscherová, Ing. Bc. Jan Maršák, Ph.D., Ing. Ján Dráb, PhD., Martin Basila, Roman Vandák, Miroslav Jakuš, Dipl. Ing. Dušan Magula, Jože Gregorič, Ing. Juraj Jakeš, Mgr. Michal Bakyta, Nina Sankovič, Juraj Smatana, Rastislav Zamboj',
        eventDate: '12. - 13. 10. 2020',
        venue: 'Demänovská Dolina, Hotel Grand Jasná****',
        img: 'https://www.profivzdelavanie.sk/media/Base/1912/Image45422.png',
        _key: '29707512',
    },
    {
        title: 'Kybernetická bezpečnosť 2020',
        link: 'http://konferencie.profivzdelavanie.sk/2020/kyberneticka-bezpecnost/',
        lectors:
            'JUDr. Ing. Miroslav Chlipala, PhD., Ing. Tomáš Zaťko, Ing. Ivan Makatura, CRISC, CDPSE, Mgr. Marek Zeman, PhD., JUDr. Štefan Pilár, JUDr. Ing. Jindřich Kalíšek, Ph.D. CIPP/E CIPM, Ondrej Krajč, Ing. David Dvořák, CISA, CISM, CRISC, CDPSE, ITIL Expert, ISA/IEC 62443 Cybersecurity Expert, Pavol Draxler, Jaroslav Oster, Ján Lichvár, Ladislav Hodinka, Igor Straka, Zsolt Géczi, Mgr. Rastislav Janota, Matej Šalmík, Lukáš Neduchal, Ing. Tomáš Hettych, CISA, CISM, CGEIT, CRISC, CDPSE',
        eventDate: '05. - 06. 10. 2020',
        venue: 'Demänovská Dolina, Hotel Grand Jasná****',
        img: 'https://www.profivzdelavanie.sk/media/Base/2001/Image45813.png',
        _key: '29707511',
    },
    {
        title: 'Verejné obstarávanie 2020',
        link: 'http://konferencie.profivzdelavanie.sk/2020/verejne-obstaravanie/',
        lectors:
            'JUDr. Róbert Majchrák, JUDr. Ivan Holič, Mgr. Katarína Hreusová, Mgr. Michal Garaj, JUDr. Zuzana Krajčovičová, Mgr. Ján Kapec, Branislav Hudec, Mgr. Pavel Herman, Mgr. Ondrej Čurilla',
        eventDate: '24. - 25. 09. 2020',
        venue: 'Tatranská Lomnica, Grandhotel Praha****',
        img: 'https://www.profivzdelavanie.sk/media/Base/1912/Image45424.png',
        _key: '29707510',
    },
    {
        title: 'Dane, účtovníctvo a mzdy pod lupou v roku 2020',
        link: 'http://konferencie.profivzdelavanie.sk/2020/dane-a-uctovnictvo-pod-lupou/',
        lectors: 'Ing. Vladimír Ozimý, RNDr. Jozef Mihál, Ing. Soňa Ugróczy',
        eventDate: '16. - 18. 09. 2020',
        venue: 'Vysoké Tatry, Grand hotel Permon****',
        img: 'https://www.profivzdelavanie.sk/media/Base/1912/Image45513.jpg',
        _key: '29707509',
    },
    {
        title: 'Daňový a odvodový kolotoč, ukončenie roka 2019 a zmeny pre rok 2020 v Grand Hoteli Starý Smokovec',
        link: 'http://konferencie.profivzdelavanie.sk/2019/danovy-a-odvodovy-kolotoc-ukoncenie-roka-2019-a-zmeny-pre-rok-2020-Smokovec/',
        lectors: 'Ing. Vladimír Ozimý, RNDr. Jozef Mihál, Ing. Soňa Ugróczy',
        eventDate: '09. - 11. 12. 2019',
        venue: 'Vysoké Tatry',
        img: 'https://www.profivzdelavanie.sk/media/Base/1901/Image40709.png',
        _key: '29707508',
    },
    {
        title: 'Zmeny v oblasti daní, účtovníctva a miezd od 1. 1. 2020',
        link: 'http://konferencie.profivzdelavanie.sk/2019/zmeny-v-oblasti-dani-uctovnictva-a-miezd-od-1-1-2020/',
        lectors: 'Ing. Mária Horváthová, Júlia Pšenková, JUDr. Ľubica Masárová, PhD., Ing. Alica Orda Oravcová',
        eventDate: '04. 12. 2019',
        venue: 'Bratislava',
        img: 'https://www.profivzdelavanie.sk/media/Base/1910/Image44902.png',
        _key: '29707507',
    },
    {
        title: 'Zmeny v oblasti daní, účtovníctva a miezd od 1. 1. 2020',
        link: 'http://konferencie.profivzdelavanie.sk/2019/zmeny-v-oblasti-dani-uctovnictva-a-miezd-od-1-1-2020/',
        lectors: 'Ing. Vladimír Ozimý, JUDr. Patrik Benčík, RNDr. Jana Motyčková',
        eventDate: '02. 12. 2019',
        venue: 'Žilina',
        img: 'https://www.profivzdelavanie.sk/media/Base/1910/Image44903.png',
        _key: '29707506',
    },
    {
        title: 'Daňový a odvodový kolotoč, ukončenie roka 2019 a zmeny pre rok 2020 v Hoteli Kaskady',
        link: 'http://konferencie.profivzdelavanie.sk/2019/danovy-a-odvodovy-kolotoc-ukoncenie-roka-2019-a-zmeny-pre-rok-2020-Kaskady/',
        lectors: 'Ing. Vladimír Ozimý, RNDr. Jozef Mihál, Ing. Soňa Ugróczy',
        eventDate: '27. - 29. 11. 2019',
        venue: 'Sliač',
        img: 'https://www.profivzdelavanie.sk/media/Base/1910/Image44693.png',
        _key: '29707505',
    },
    {
        title: 'Pracovné právo 2019',
        link: 'http://konferencie.profivzdelavanie.sk/2019/pracovne-pravo/',
        lectors:
            'prof. JUDr. Helena Barancová, DrSc., JUDr. Jana Žuľová, PhD., Ing. Nora Lauková, doc. JUDr. Marcel Dolobáč PhD., Mgr. Miroslava Mošonová',
        eventDate: '18. - 19. 11. 2019',
        venue: 'Demänovská Dolina',
        img: 'https://www.profivzdelavanie.sk/media/Base/1911/Image44983.png',
        _key: '29707504',
    },
    {
        title: 'DNA Lídra 2019',
        link: 'http://dnalidra.sk/2019',
        lectors: 'Ján Cifra, Kristína Matiová, Maroš Molnár, Andy Winson, Martin Pecko, Stanislava Pecková',
        eventDate: '23. 10. 2019',
        venue: 'Bratislava',
        img: 'https://www.profivzdelavanie.sk/media/Base/1904/Image41887.jpg',
        _key: '29707503',
    },
    {
        title: 'Trestné právo 2019',
        link: 'http://konferencie.profivzdelavanie.sk/2019/trestne-pravo/',
        lectors: 'JUDr. Ján Šanta, PhD., LL.M., MBA, prof. JUDr. Tomáš Strémy, PhD., prof. JUDr. Jozef Čentéš, PhD.',
        eventDate: '21. - 22. 10. 2019',
        venue: 'Demänovská Dolina',
        img: 'https://www.profivzdelavanie.sk/media/Base/1901/Image40767.png',
        _key: '29707502',
    },
    {
        title: 'Daňový a odvodový kolotoč v roku 2019',
        link: 'http://konferencie.profivzdelavanie.sk/2019/danovy-a-odvodovy-kolotoc/',
        lectors: 'Ing. Vladimír Ozimý, RNDr. Jozef Mihál, Ing. Soňa Ugróczy',
        eventDate: '17. 10. 2019',
        venue: 'Bratislava',
        img: 'https://www.profivzdelavanie.sk/media/Base/1906/Image42976.png',
        _key: '29707501',
    },
    {
        title: 'UPTERDAM 2019',
        link: 'https://upterdam.com/sk-sk/',
        lectors: null,
        eventDate: '08. 10. 2019',
        venue: 'Bratislava',
        img: 'https://www.profivzdelavanie.sk/media/Base/1904/Image42033.jpg',
        _key: '29752021',
    },
    {
        title: 'Zákon o informačných technológiách vo verejnej správe 2019',
        link: 'http://konferencie.profivzdelavanie.sk/2019/zakon-o-IT-vo-verejnej-sprave/',
        lectors:
            'JUDr. Mgr. Jozef Medelský, PhD., Michal Korytko, Ervín Šimko, Ing. Magdaléna Šergovičová, Mgr. Simona Habaľová, JUDr. Monika Mušutová, JUDr. Jozef Andraško, PhD., Ing. Pavol Frič, PhD., Ing. Ľuboš Batěk',
        eventDate: '23. - 24. 09. 2019',
        venue: 'Demänovská Dolina',
        img: 'https://www.profivzdelavanie.sk/media/Base/1907/Image43146.png',
        _key: '29707500',
    },
    {
        title: 'GDPR a zákon o ochrane osobných údajov 2019',
        link: 'http://konferencie.profivzdelavanie.sk/2019/GDPR-ochrana-osobnych-udajov-podla-noveho-zakona/',
        lectors:
            'Ing. Ivan Makatura, CRISC, CDPSE, JUDr. Lucia Bezáková, Mgr. Irena Hudecová, Ing. Martin Kuľhavý, Marek Kaman, Mgr. Ladislav Bačo, Michal Korytko, Mgr. Ján Golais, Ondrej Krajč, Mgr. Zuzana Motúzová',
        eventDate: '04. - 05. 06. 2019',
        venue: 'Tatranská Lomnica',
        img: 'https://www.profivzdelavanie.sk/media/Base/1904/Image41951.png',
        _key: '29707499',
    },
    {
        title: 'Dane a účtovníctvo pod lupou v roku 2019',
        link: 'http://konferencie.profivzdelavanie.sk/2019/dane-a-uctovnictvo-pod-lupou/',
        lectors: 'Ing. Vladimír Ozimý, Ing. Soňa Ugróczy',
        eventDate: '03. - 05. 06. 2019',
        venue: 'Vysoké Tatry',
        img: 'https://www.profivzdelavanie.sk/media/Base/1812/Image40592.jpg',
        _key: '29707498',
    },
    {
        title: 'Konkurz a reštrukturalizácia 2019',
        link: 'http://konferencie.profivzdelavanie.sk/2019/konkurz-a-restrukturalizacia',
        lectors:
            'Prof. JUDr. Milan Ďurica, PhD., JUDr. Branislav Pospíšil, JUDr. Jaroslav Macek, JUDr. Danica Birošová',
        eventDate: '14. - 15. 05. 2019',
        venue: 'Trenčín',
        img: 'https://www.profivzdelavanie.sk/media/Base/1902/Image41393.png',
        _key: '29707497',
    },
    {
        title: 'Verejné obstarávanie 2019',
        link: 'http://konferencie.profivzdelavanie.sk/2019/verejne-obstaravanie/',
        lectors:
            'JUDr. Peter Kubovič, JUDr. Róbert Majchrák, JUDr. Ivan Holič, Mgr. Michal Garaj, JUDr. Zuzana Krajčovičová, Mgr. Ján Kapec, Branislav Hudec, Mgr. Jozef Bálint',
        eventDate: '09. - 10. 04. 2019',
        venue: 'Tatranská Lomnica',
        img: 'https://www.profivzdelavanie.sk/media/Base/1901/Image40672.png',
        _key: '29707496',
    },
    {
        title: 'Kybernetická bezpečnosť 2019',
        link: 'http://konferencie.profivzdelavanie.sk/2019/kyberneticka-bezpecnost/',
        lectors:
            'Mgr. Zuzana Halásová, PhD., JUDr. Ing. Miroslav Chlipala, PhD., Ing. Ivan Makatura, CRISC, CDPSE, Pavol Adamec, Mgr. Marek Zeman, PhD., JUDr. Štefan Pilár, JUDr. Ing. Jindřich Kalíšek, Ph.D. CIPP/E CIPM',
        eventDate: '01. - 02. 04. 2019',
        venue: 'Tatranská Lomnica',
        img: 'https://www.profivzdelavanie.sk/media/Base/1901/Image40674.png',
        _key: '29707495',
    },
    {
        title: 'Odpadové hospodárstvo 2019',
        link: 'http://konferencie.profivzdelavanie.sk/2019/odpadove-hospodarstvo/',
        lectors:
            'Ing. Peter Gallovič, Ing. Jarmila Ďurďovičová, Ing. Tomáš Schabjuk, Mgr. Peter Valent, Ing. Peter Šimurka, Mgr. Michaela Ploszeková MBA, RNDr. Peter Krasnec, PhD., MBA, JUDr. Katarína Brath Liebscherová, Michal Sebíň, JUDr. Veronika Václavková, Ing. Martin Hauskrecht',
        eventDate: '21. - 22. 03. 2019',
        venue: 'Demänovská Dolina',
        img: 'https://www.profivzdelavanie.sk/media/Base/1902/Image41185.png',
        _key: '29707494',
    },
    {
        title: 'Daňový a odvodový kolotoč a legislatívne zmeny pre rok 2019 v Hoteli Patria',
        link: 'http://konferencie.profivzdelavanie.sk/2018/danovy-a-odvodovy-kolotoc-a-legislativne-zmeny-pre-rok-2019-patria/',
        lectors: 'Ing. Vladimír Ozimý, RNDr. Jozef Mihál, Ing. Soňa Ugróczy',
        eventDate: '10. - 12. 12. 2018',
        venue: 'Vysoké Tatry',
        img: 'https://www.profivzdelavanie.sk/media/Base/1806/Image38472.jpg',
        _key: '29707493',
    },
    {
        title: 'Zmeny v oblasti daní, účtovníctva a miezd od 1. 1. 2019',
        link: 'http://konferencie.profivzdelavanie.sk/2018/zmeny-v-oblasti-dani-uctovnictva-a-miezd-od-1-1-2019/',
        lectors:
            'Ing. Mária Horváthová, Ing. Vladimír Ozimý, JUDr. Patrik Benčík, RNDr. Jana Motyčková, JUDr. Ľubica Masárová, PhD., Ing. Alica Orda Oravcová',
        eventDate: '04. 12. 2018',
        venue: 'Bratislava',
        img: 'https://www.profivzdelavanie.sk/media/Base/1806/Image38470.jpg',
        _key: '29707492',
    },
    {
        title: 'Daňový a odvodový kolotoč a legislatívne zmeny pre rok 2019 v Hoteli Kaskady',
        link: 'http://konferencie.profivzdelavanie.sk/2018/danovy-a-odvodovy-kolotoc-a-legislativne-zmeny-pre-rok-2019/',
        lectors: 'Ing. Vladimír Ozimý, RNDr. Jozef Mihál, Ing. Soňa Ugróczy',
        eventDate: '28. - 30. 11. 2018',
        venue: 'Sliač',
        img: 'https://www.profivzdelavanie.sk/media/Base/1806/Image38466.jpg',
        _key: '29707491',
    },
    {
        title: 'DNA Lídra 2018',
        link: 'https://dnalidra.sk/2018/',
        lectors: 'Ivo Toman, Artur Gevorkyan, Šimon Šicko, Matej Tóth, Bekim Aziri, Petra Toth',
        eventDate: '23. 10. 2018',
        venue: 'Bratislava',
        img: 'https://www.profivzdelavanie.sk/media/Base/1806/Image38588.jpg',
        _key: '29707490',
    },
    {
        title: 'Trestné činy hospodárske, daňové a sprenevera eurofondov',
        link: 'http://konferencie.profivzdelavanie.sk/2018/trestne-ciny-hospodarske/',
        lectors:
            'JUDr. Ján Šanta, PhD., LL.M., MBA, prof. JUDr. Tomáš Strémy, PhD., prof. JUDr. Jozef Čentéš, PhD., Jozef Maruščák LLB, Ing. Dušan Daniška',
        eventDate: '18. - 19. 10. 2018',
        venue: 'Demänovská Dolina',
        img: 'https://www.profivzdelavanie.sk/media/Base/1807/Image38827.png',
        _key: '29707489',
    },
    {
        title: 'Daňový a odvodový kolotoč v roku 2018',
        link: 'http://konferencie.profivzdelavanie.sk/2018/danovy-a-odovdovy-kolotoc-2018/',
        lectors: 'Ing. Vladimír Ozimý, RNDr. Jozef Mihál, Ing. Soňa Ugróczy',
        eventDate: '16. 10. 2018',
        venue: 'Bratislava',
        img: 'https://www.profivzdelavanie.sk/media/Base/1805/Image38207.jpg',
        _key: '29707487',
    },
    {
        title: 'GDPR a osobné údaje v panelovej diskusii so 7 odborníkmi',
        link: 'http://konferencie.profivzdelavanie.sk/2018/gdpr-a-osobne-udaje/',
        lectors: 'Ing. Ivan Makatura, CRISC, CDPSE, Mgr. Irena Hudecová',
        eventDate: '14. 06. 2018',
        venue: 'Bratislava',
        img: 'https://www.profivzdelavanie.sk/media/Base/1805/Image38061.png',
        _key: '29707486',
    },
    {
        title: 'Dane a účtovníctvo pod lupou v roku 2018',
        link: 'http://konferencie.profivzdelavanie.sk/2018/dane-a-uctovnictvo-pod-lupou-2018/',
        lectors: 'Ing. Vladimír Ozimý, JUDr. Marcela Macová, PhD., Ing. Soňa Ugróczy',
        eventDate: '28. - 30. 05. 2018',
        venue: 'Vysoké Tatry',
        img: 'https://www.profivzdelavanie.sk/media/Base/1802/Image36774.jpg',
        _key: '29707485',
    },
    {
        title: 'Odpady e+ 2018',
        link: 'http://konferencie.profivzdelavanie.sk/2018/odpady-2018/',
        lectors:
            'Mgr. Janette Smažáková, Eleonóra Šuplatová, Mgr. Peter Antal, Ing. Peter Benko, RNDr. Ján Čepček, PhD., JUDr. Ľubomíra Kubišová, Ing. Zuzana Kocunová, Evert Lichtenbelt, JUDr. Daniela Medžová, Nobuhiro Tanigaki, Mgr. Oľga Trcková, Ing. Viera Špalková, PhD., Oľga Trcková',
        eventDate: '26. - 27. 04. 2018',
        venue: 'Banská Bystrica',
        img: 'https://www.profivzdelavanie.sk/media/Base/1803/Image37287.jpg',
        _key: '29707484',
    },
    {
        title: 'Konkurz a reštrukturalizácia aktuálne v roku 2018',
        link: 'http://konferencie.profivzdelavanie.sk/2018/konkurz-a-restrukturalizacia_2018/',
        lectors: 'JUDr. Martin Maliar, JUDr. Boris Tóth, JUDr. Branislav Pospíšil, JUDr. Jaroslav Macek',
        eventDate: '24. - 25. 04. 2018',
        venue: 'Trenčín',
        img: 'https://www.profivzdelavanie.sk/media/Base/1802/Image36924.jpg',
        _key: '29707483',
    },
    {
        title: 'eCommerce Conference 2018',
        link: 'https://konferencie.pp.sk/2018/ecommerce-conference-2018/',
        lectors: null,
        eventDate: '12. - 13. 04. 2018',
        venue: 'Senec',
        img: 'https://www.profivzdelavanie.sk/media/Base/1802/Image37282.jpg',
        _key: '29749601',
    },
    {
        title: 'Ochrana osobných údajov podľa nového zákona a Nariadenia Európskeho parlamentu a Rady (EÚ) č. 2016/679 („GDPR")',
        link: 'http://konferencie.pp.sk/2017/ochrana-osobnych-udajov-podla-noveho-zakona/',
        lectors: 'JUDr. Marcela Macová, PhD., JUDr. Jakub Uhrinčať, JUDr. Pavol Szabo, LL.M., Mgr. Irena Hudecová',
        eventDate: '06. 12. 2017',
        venue: 'Bratislava',
        img: 'https://www.profivzdelavanie.sk/media/Base/1711/Image35731.jpg',
        _key: '29746675',
    },
    {
        title: 'Hospitality & Tourism Summit 2018',
        link: 'http://konferencie.pp.sk/2018/hospitality_and_tourismus_summit_2018/',
        lectors:
            'Rozwitha Bieber, Ľuboš Fellner, Marianna Revallová, Vladimír Cintula, Tomáš Tiefenbach, Tomáš Sokologorský, Denisa Prokšová, Janka Leitnerová, Tatiana Pribylincová, Mária Reháková, Juraj Bais',
        eventDate: '26. 01. 2018',
        venue: 'Bratislava',
        img: 'https://www.profivzdelavanie.sk/media/Base/1712/Image36043.jpg',
        _key: '29744813',
    },
    {
        title: 'eCommerce Conference 2017',
        link: 'http://konferencie.pp.sk/2017/ecommerce-conference-2017/',
        lectors:
            'Ing. Vladimír Ozimý, Ing. Peter Minárik, Daniel Duriš, Andrej Sebestyén, Katarína Molnárová, Eva Rajčáková, Pavol Adamčák, Katarína Tallová, Peter Jakuš, Jozef Dvorský, Marián Zeleniak, Ivan Jašek, Jana Dlhopolčeková, Alexander Jančo, Peter Horník, Alexander Botev, Ing. Tomáš Zaťko, JUDr. Róbert Bános, Michal Mervart, Michal Král, Andrea Slaná, Josef Kunčar, Martin Turčan, Zuzana Bednarzová, Peter Nemčok',
        eventDate: '11. - 13. 09. 2017',
        venue: 'Vysoké Tatry',
        img: 'https://www.profivzdelavanie.sk/media/Base/1708/Image34829.jpg',
        _key: '29738182',
    },
    {
        title: 'Zmeny v oblasti daní, účtovníctva a miezd od 1. 1. 2018',
        link: 'http://konferencie.profivzdelavanie.sk/2017/zmeny-v-oblasti-dani-uctovnictva-a-miezd/',
        lectors:
            'Ing. Vladimír Ozimý, JUDr. Marcela Macová, PhD., JUDr. Patrik Benčík, Ing. Mgr. Martin Tužinský, PhD., RNDr. Jana Motyčková, JUDr. Ľubica Masárová, PhD.',
        eventDate: '28. 11. 2017',
        venue: 'Žilina',
        img: 'https://www.profivzdelavanie.sk/media/Base/1711/Image35730.jpg',
        _key: '29707482',
    },
    {
        title: 'Daňový a odvodový kolotoč a legislatívne zmeny pre rok 2018',
        link: 'http://konferencie.pp.sk/2017/danovy-kolotoc-2017-kaskady/',
        lectors: 'Ing. Vladimír Ozimý, RNDr. Jozef Mihál, Ing. Mgr. Martin Tužinský, PhD.',
        eventDate: '29. 11. 2017 - 01. 12. 2017',
        venue: 'Sliač',
        img: 'https://www.profivzdelavanie.sk/media/Base/1710/Image35439.jpg',
        _key: '29707478',
    },
    {
        title: 'Daňový a odvodový kolotoč',
        link: 'http://konferencie.profivzdelavanie.sk/2017/danovy-a-odovdovy-kolotoc-2017/',
        lectors: 'Ing. Vladimír Ozimý, RNDr. Jozef Mihál, Ing. Mgr. Martin Tužinský, PhD.',
        eventDate: '03. 10. 2017',
        venue: 'Bratislava',
        img: 'https://www.profivzdelavanie.sk/media/Base/1708/Image34832.jpg',
        _key: '29707481',
    },
    {
        title: 'Trestná zodpovednosť právnických osôb, delikty v konkurznom a daňovom práve',
        link: 'http://konferencie.pp.sk/2017/trestna-zodpovednost-pravnickych-osob_2017/',
        lectors: 'JUDr. Ján Šanta, PhD., LL.M., MBA, prof. JUDr. Jozef Čentéš, PhD.',
        eventDate: '27. 09. 2017',
        venue: 'Bratislava',
        img: 'https://www.profivzdelavanie.sk/media/Base/1708/Image34830.jpg',
        _key: '29707480',
    },
];
